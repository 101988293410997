
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        




































































































/* stylelint-disable declaration-no-important, max-nesting-depth, no-descending-specificity */
.speed-comparator-outer {
  background: $c-light-gray;

  margin-bottom: -6rem;

  & + * {
    padding-top: 0 !important;

    > * {
      padding-top: 8rem !important;
    }
  }
}

.speed-comparator {
  @extend %box-shadow;

  position: relative;
  z-index: 90; /* Check */
  margin: 0 auto;
  padding: 2.5rem;
  background-color: $white;
  border-radius: 10px;
  transform: translateY(-$spacing * 5);

  @include mq(l) {
    padding: 2.5rem 5rem;
    width: col(8);
    max-width: 72rem;
    transform: translateY(-$spacing * 6);
  }
}

.speed-comparator__title {
  margin-top: 0;
  margin-bottom: $spacing;
  font-size: 2.2rem;

  @include mq(l) {
    font-size: 3.2rem;
    text-align: center;
  }
}

.speed-comparator__items {
  margin-bottom: 1.5rem;
  padding: 1.5rem 2rem;
  background-color: $c-cultured;
  border-radius: 10px;
}

.speed-comparator__item {
  display: flex;
  align-items: center;
}

.speed-comparator__content {
  width: 100%;
}

.speed-comparator__range {
  width: 100%;
  max-width: 400rem;
  height: 1.4rem;
  width: 100%;
  max-width: 400rem;
  height: 1.4rem;
  background-color: $c-pink-medium;
  background-image: linear-gradient(
    to left,
    $c-pink-medium 50%,
    $c-gray-lightest 50%,
    $c-gray-lightest 100%
  );
  background-size: 200%;
  background-position-x: (200%);
  border-radius: 5rem;

  @include mq($until: l) {
    order: 3;
  }
}

.speed-comparator__label {
  @extend %fw-bold;

  margin-top: $spacing * 0.5;
  color: $c-pink-medium;
  font-family: $ff-alt;
  font-size: 1.2rem;
  line-height: 1;

  @include mq(l) {
    font-size: 1.6rem;
  }
}

.speed-comparator__infos {
  @extend %fw-bold;

  margin-left: 2.5rem;
  width: 10rem;
  color: $c-pink-medium;
  font-family: $ff-alt;
  font-size: 1.3rem;
  line-height: 1;

  ::v-deep strong {
    font-size: 2.8rem;
  }

  @include mq(l) {
    font-size: 1.6rem;

    ::v-deep strong {
      font-size: 4.2rem;
    }
  }
}

.speed-comparator__disclaimer {
  color: $c-blue-pale;
  font-size: 1.3rem;
  line-height: 24px;

  @include mq($until: l) {
    display: none;
  }
}
