
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        

















































.section-tip {
  position: relative;
  display: flex;
  align-items: center;
}

.section-tip__icon {
  margin-right: $spacing * 2;

  svg {
    margin: auto;
    fill: $c-pink-medium;
  }
}

.section-tip__content {
  font-family: $ff-alt;
  font-weight: 700;
}

.section-tip__content__title {
  font-size: 2.5rem;
  line-height: 1;
}

.section-tip__content__link {
  font-size: 1.8rem;
}
