
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        






















































































































































/* stylelint-disable selector-max-combinators, selector-max-compound-selectors, max-nesting-depth, no-descending-specificity, declaration-no-important */
.hub-net,
[class*='hub-net--'] {
  overflow: hidden;

  ::v-deep {
    .dominopill__title {
      @include mq('l') {
        max-width: 55rem;
        margin-top: 9rem;
      }
    }

    .flickity-viewport {
      margin-bottom: $spacing * 1.5;
    }

    .flickity-button:hover:not(:disabled) {
      svg {
        fill: $c-anthracite-light;
      }
    }

    .btn--no-icon span,
    .btn__link__title {
      font-size: 1.6rem;
    }
  }
}

.hub-net__hero {
  @include mq('l') {
    max-height: 69rem;
  }
}

.hub-net__testimonial {
  @include mq($until: l) {
    margin-top: $spacing * 3;
  }
}

.hub-net__centered-block {
  background-color: #fafafa;
}

.hub-net__discover {
  margin-top: -$spacing * 3;
  background: $c-light-gray;

  ::v-deep .flickity-page-dots .dot {
    background-color: white;

    &.is-selected {
      background-color: silver;
    }
  }

  @include mq('l') {
    margin-top: -$spacing * 4;
  }
}

.hub-net__gigagame {
  color: #fff;
  background-color: #070112;

  ::v-deep {
    .section-row__content {
      margin-top: 0;
    }

    .section-row__content__logo {
      margin-bottom: 0;
    }

    .section-row__content__title {
      margin-bottom: 1.5rem;
    }

    .section-row__content__text {
      p {
        margin-top: 0;
      }
    }

    img {
      @include mq($until: l) {
        object-fit: contain;
      }
    }
  }
}
